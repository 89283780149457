.container {
    position: absolute;
    bottom: rem(50px);
    padding-bottom: rem(10px);
    left: 0;
    right: 0;
    z-index: 2;
}

.content {
    padding: var(--mantine-spacing-xs);
    padding-right: var(--mantine-spacing-md);
    padding-left: var(--mantine-spacing-md);
    gap: var(--mantine-spacing-xs);
    text-align: center;
    justify-content: space-between;
    align-items: center;
}