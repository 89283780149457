.grid {
    display: grid;
    grid-column-gap: var(--mantine-spacing-lg);
    grid-row-gap: var(--mantine-spacing-lg);

    @media (min-width: $mantine-breakpoint-xs) {
        grid-template-columns: repeat(2, 1fr);
    }

    @media (min-width: $mantine-breakpoint-sm) {
        grid-template-columns: repeat(3, 1fr);
    }

    @media (min-width: $mantine-breakpoint-md) {
        grid-template-columns: repeat(4, 1fr);
    }

    @media (min-width: $mantine-breakpoint-xl) {
        grid-template-columns: repeat(5, 1fr);
    }

    @media (min-width: 1650px) {
        grid-template-columns: repeat(6, 1fr);
    }

    @media (min-width: 2400px) {
        grid-template-columns: repeat(7, 1fr);
    }
}

.flexGrid {
    display: flex;
    flex-direction: row;
    gap: var(--mantine-spacing-lg);
    overflow: auto;
}

.flexColumn {
    flex: 0 0 rem(277px);
}